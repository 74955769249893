<template>
  <div class="step-container">
    <div class="title fs-24 fc-1" v-show="textList.length > 0">{{title}}</div>
    <tr class="info-list" v-for="(item, index) in textList" :key="index">
      <div class="info-item">
        <div class="sub-title fs-18 fc-3">{{item.title}}</div>
        <div class="des fs-16 fc-3" v-html="item.content"></div>
      </div>
    </tr>
    <div class="step-nav">
      <el-steps direction="vertical" finish-status="wait" space="50px" :active="activeStep">
        <el-step
          class="step-item"
          v-for="(item, index) in textList"
          :key="index"
          :title="item.title"
          @click.native="jump(index)"
        >{{item.title}}</el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepPage",
  props: {
    textList: {
      type: Array,
      default: () => {}
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeStep: 0
    };
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  methods: {
    // 当元素滚动条被滚动时运行的脚本
    onScroll() {
      var jump = document.querySelectorAll(".info-list");
      var distance = document.documentElement.scrollTop;
      this.activeStep = 0;
      jump.forEach((item, index) => {
        if (distance >= item.offsetTop) {
          this.activeStep = index;
        }
      });
    },
    // 锚点点击
    jump(index) {
      this.activeStep = index;
      // 用 class=".info-list" 添加锚点，此时的类名要放在tr上，放在td上不对，
      // 以后做的时候要注意这点，不是表格的时候，如何放置锚点
      let jump = document.querySelectorAll(".info-list");
      // 通过 offsetTop 获取对象到窗体顶部的距离，然后赋值给 scrollTop，就能实现锚点的功能
      let total = jump[index].offsetTop;
      // scrollTop滚动条距离页面的距离
      let distance =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          window.pageYOffset = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
          window.pageYOffset = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          window.pageYOffset = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
          window.pageYOffset = total;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.step-container {
  padding: 0.38rem 2.5rem 0.72rem 5.2rem;
  position: relative;
  .step-nav {
    position: fixed;
    right: 10rem;
    top: 2rem;
    flex-direction: column;
    align-items: flex-end;
    border-right: 0.02rem solid #d4d4d4;
    .step-item {
      padding-bottom: 0.08rem;
      flex-basis: 0.2rem !important;
      .el-step__main {
        cursor: pointer;
        .el-step__title {
          text-align: right;
        }
      }
    }
  }
  .title {
    font-weight: 500;
  }
  .info-list {
    .info-item {
      padding-top: 0.4rem;
      .sub-title {
        font-weight: 500;
      }
    }
  }
}
</style>